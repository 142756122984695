import React from 'react';
import { firestore } from '../../lib/firebase.prod';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Center } from '@mantine/core';
import { Spinner } from '../styles';

import { IconArrowBigDownLines } from '@tabler/icons-react';
import { useHistory } from 'react-router-dom';
import { UserCardWrapper } from './styles';

import { collection, query, limit, getDocs, startAfter, orderBy } from 'firebase/firestore';
import { Avatar } from '@heroui/react';

const AllUsers = () => {
  const history = useHistory();
  const usersQuery = query(collection(firestore, 'users'), limit(50), orderBy('created_at', 'desc'));

  const [users, setUsers] = useState([]);
  const [cursorDoc, setCursorDoc] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDocs(usersQuery).then(snapshot => {
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      const firstUsers = snapshot.docs.map(contentObj => ({
        ...contentObj.data(),
        docId: contentObj.id,
      }));
      setCursorDoc(lastVisible);
      setUsers(firstUsers);
      setLoading(false);
    });
  }, []);

  async function getMore() {
    setLoading(true);
    const paginatedQuery = query(
      collection(firestore, 'users'),
      orderBy('created_at', 'desc'),
      startAfter(cursorDoc),
      limit(50),
    );
    return getDocs(paginatedQuery)
      .then(snapshot => {
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        setCursorDoc(lastVisible);
        const paginatedUsers = snapshot.docs.map(contentObj => ({ ...contentObj.data(), docId: contentObj.id }));
        setUsers(prev => [...prev, ...paginatedUsers]);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  return (
    <UserCardWrapper>
      <div className="grid grid-cols-1 gap-6 mt-40 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mx-10 sm:grid-cols-1 xs:grid-cols-1 2xl:grid-cols-6 hover:cursor-pointer">
        {users.map(({ photoURL, name, id }) => {
          return (
            <a
              className="text-white rounded-lg shadow-md p-6 min-w-[200px] bg-white hover:bg-[rgb(22,22,58)] group"
              key={id}
              href={`/users/${id}`}
              onClick={e => {
                e.preventDefault();
                history.push(`/users/${id}`);
              }}
            >
              <div className="flex items-center mb-4 flex-col justify-center group-hover:text-white">
                <Avatar isBordered className="w-20 h-20 text-large" alt={`User Name:${name}`} src={photoURL} />

                <div className="text-center py-2 hover:text-white">
                  <h2 className="text-xl font-bold text-slate-800 group-hover:text-white">{name}</h2>
                </div>
              </div>
            </a>
          );
        })}
      </div>
      <div>
        <Center style={{ height: 200 }}>
          {loading ? (
            <Spinner>...</Spinner>
          ) : (
            <Button
              onClick={getMore}
              radius={4}
              rightIcon={<IconArrowBigDownLines size={14} />}
              disabled={loading}
              className="lg:w-1/4 md:w-1/8 xs:w-full m-10 rounded-md text-slate-100 text-xl h-12 bg-purple-300 hover:bg-gray-100 hover:text-purple-600 font-montserrat"
            >
              Load more
            </Button>
          )}
        </Center>
      </div>
    </UserCardWrapper>
  );
};

export default AllUsers;
