import App from './App';
import 'fontsource-roboto';
import { FirebaseContext } from './context/firebase';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { HeroUIProvider } from '@heroui/react';

import './index.css';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <FirebaseContext.Provider value={{}}>
    <div className="bg-white">
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <Notifications position="top-center" zIndex={2077} />
        <HeroUIProvider>
          <App />
        </HeroUIProvider>
      </MantineProvider>
    </div>
  </FirebaseContext.Provider>,
);
