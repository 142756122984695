import { Highlight } from 'react-instantsearch';
import { getPropertyByPath } from 'instantsearch.js/es/lib/utils/getPropertyByPath';
import { truncateString } from '../helpers/util-fns';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

export const Hit = ({ hit }) => {

  return (
    <Link to={{ pathname: hit.path, state: {} }} className="cursor-pointer">
      <article className="w-[280px]">
        <div className="flex flex-col">
          <div className="hit-image object-cover">
            <img src={hit.photoURL} className="object-cover rounded-sm" />
          </div>

          <div className="p-2 flex flex-col justify-between">
            <div className="hit-title text-purple-600 uppercase pt-[1px] w-full max-w-xs truncate  text-xs">
              <Highlight attribute="title" hit={hit} />
            </div>

            <div className="my-1">
              {getPropertyByPath(hit, 'description') ? (
                <span className="text-xs">{truncateString(getPropertyByPath(hit, 'description'), 90)}</span>
              ) : // <Snippet attribute="description" hit={hit} />
              null}
            </div>
            <div className="py-3">
              {getPropertyByPath(hit, 'date') ? (
                <>
                  <span className="text-xs text-slate-700 px-1">
                    {format(new Date(getPropertyByPath(hit, 'date')), 'MMM d, yyyy')}
                  </span>
                  <span className="text-xs text-slate-700">@</span>
                  <span className="text-xs text-slate-700 px-1">
                    {format(new Date(getPropertyByPath(hit, 'date')), 'HH:mm')}
                  </span>
                </>
              ) : null}
            </div>

            {/* <div className="hit-category mt-3 mb-[4px] flex justify-end flex-wrap gap-1 align-bottom">
              {getPropertyByPath(hit, 'creator_name') ? (
                <Highlight
                  attribute="creator_name"
                  hit={hit}
                  className="bg-rose-200 text-laste-900 rounded-full px-[6px] text-[8px] text-center mx-[1px]"
                />
              ) : null}
              {getPropertyByPath(hit, 'category') ? (
                <Highlight
                  attribute="category"
                  hit={hit}
                  className="inline-block bg-amber-100 text-laste-900 rounded-full px-2 text-[8px] text-center mx-[1px]"
                />
              ) : null}
              {getPropertyByPath(hit, 'country') ? (
                <Highlight
                  attribute="country"
                  hit={hit}
                  className="inline-block bg-teal-100 text-laste-900 rounded-full px-2 text-[8px] text-center mx-[1px]"
                />
              ) : null}

              {getPropertyByPath(hit, 'date') ? (
                <span className="inline-block bg-sky-200 text-laste-900 rounded-full px-2 text-[8px] text-center mx-[1px]">
                  {new Date(getPropertyByPath(hit, 'date')).getFullYear()}
                </span>
              ) : null}
            </div> */}
          </div>
        </div>
      </article>
    </Link>
  );
};
