import { useParams } from 'react-router-dom';
import { ARTICLES } from '../articlesData';
import { ArticleWrapper } from './styles';

const simpleSection = section => (
  <section key={section.title} className="text-slate-800">
    <h2 className="text-xl font-bold py-2 italic underline underline-offset-4 ">{section.title}</h2>
    {Array.isArray(section.content) ? (
      section.content.map((paragraph, i) => (
        <p key={i} className="text-md py-2">
          {paragraph}
        </p>
      ))
    ) : (
      <p section={section.title} className="text-md">
        {section.content}
      </p>
    )}
  </section>
);

const subSection = section => (
  <section key={section.title} className="text-slate-800">
    <h2 className="text-lg font-bold py-2 italic underline">{section.title}</h2>
    <h4 className="text-md mx-4 font-bold py-2">{section.subtitle}</h4>

    {section.sections.map(subSection => (
      <section key={subSection.title} className="text-slate-800 mx-4">
        <h3 className="text-md font-bold py-2 italic">{subSection.title}</h3>
        <p className="text-md">{subSection.content}</p>
      </section>
    ))}
  </section>
);

export default function Article() {
  const { slug } = useParams();
  const article = ARTICLES.find(article => article.slug === slug);
  const { title, author, published, tags, sections, id } = article;

  const imagePath = require(`../images/article-${id}.webp`);

  return (
    <ArticleWrapper>
      <div className="p-10 flex gap-4 flex-col w-2/2 md:w-1/2">
        <img src={imagePath ?? imagePathWebp} alt={title} className="w-full h-96 object-fill" />
        <section className="mb-20" aria-label={`Article about ${title}`}>
          <h1 about={title}>{title}</h1>
          <p className="text-sm text-purple-800 italic">
            Published on <time dateTime="2024-06-15T20:00:00">{published}</time> by {author}
          </p>
        </section>

        {sections.map(section => (section.type === 'paragraph' ? simpleSection(section) : subSection(section)))}

        {/* Tags */}
        <div className="flex flex-wrap gap-2 mt-10 border-t-[1px] p-2 border-purple-200">
          {tags.map(tag => (
            <span key={tag} className="italic text-slate-900 rounded-full px-[6px] text-[10px] text-center">
              {tag}
            </span>
          ))}
        </div>
      </div>
    </ArticleWrapper>
  );
}
