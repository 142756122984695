import { Button } from '@mantine/core';
import tw, { styled, css } from 'twin.macro';

export const PostMuventWrapperTwinMacro = styled.div(() => [
  tw` bg-[rgb(22,22,58)] py-20`,
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    color: black;
    padding-bottom: 6rem;

    .cropped-img {
      text-align: center;
    }

    form {
      border-radius: 1rem;
      font-family: 'Montserrat', sans-serif;
      display: block;
      justify-content: center;
      flex-direction: column;
      padding: 2rem;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      -webkit-box-shadow: 1px 1px 1px 2px #b7c7e1;
      -moz-box-shadow: 1px 1px 1px 1px #b7c7e1;
      box-shadow: 0px 0px 40px 0.2px #b7c7e1;

      input:not([type='file']):not([type='checkbox']),
      .mantine-DatePickerInput-input {
        font-family: 'Montserrat', sans-serif;
        margin: 0.5rem 0;
        border: none;
        &:focus {
          outline: none;
        }
        background: rgba(255, 255, 255, 0.29);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.45);
        width: -webkit-fill-available;
        text-transform: capitalize;
      }

      .ReactCrop {
        max-width: fit-content !important;
      }

      input[type='file'] {
        background-image: url('https://res.cloudinary.com/umuv/image/upload/v1622919587/UMUV/upload_photo.png');
        color: transparent;
        overflow: hidden;
        cursor: pointer;
        background-color: white;
        background-position: center;
        background-size: cover;
        width: 140px;
        height: 140px;
        border-radius: 100px;
        padding-left: 130px;
        margin-top: -50px;
      }

      textarea#description,
      textarea#more_info,
      textarea {
        color: #222;
        width: 100%;
        padding: 2rem;
        border-radius: 12px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.45);
      }

      .ant-select {
        margin-top: 0.7rem;
        display: block;
      }

      label {
        color: black !important;
        font-family: 'Montserrat', sans-serif;
      }

      .ant-picker-focused {
        border: none;
        box-shadow: none;
      }

      .ant-picker {
        border: none;
      }
      .ant-picker-input > input {
        padding: 0.35rem 1rem;
        border: none;
      }

      .ant-picker-ok > button {
        background-color: darkgrey !important;
        color: black !important;
        box-shadow: none !important;
      }

      button {
        margin: 1.5rem 0;
        padding: 0;
        border: 0;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        cursor: pointer;
      }

      .confirm-edit-section {
        display: flex;
        justify-content: space-around;
      }
    }
  `,
]);

export const BioFormWrapperTwinMacro = styled.div(() => [
  tw`py-10 w-full`,
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    color: black;
    padding-bottom: 2rem;

    .cropped-img {
      text-align: center;
    }

    form {
      font-family: 'Montserrat', sans-serif;
      padding: 2rem;
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
      -webkit-box-shadow: 4px 1px 1px 3px #b7c7e1;
      -moz-box-shadow: 1px 2px 1px 1px #b7c7e1;
      box-shadow: 0px 0px 100px 0.5px #b7c7e2;

      input:not([type='file']):not([type='checkbox']),
      .mantine-DatePickerInput-input {
        font-family: 'Montserrat', sans-serif;
        margin: 0.5rem 0;
        border: none;
        &:focus {
          outline: none;
        }
        background: rgba(255, 255, 255, 0.29);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.45);
        width: -webkit-fill-available;
      }

      /* .ReactCrop {
        max-width: fit-content !important;
      } */
      /* 
      input[type='file'] {
        background-image: url('https://res.cloudinary.com/umuv/image/upload/v1622919587/UMUV/upload_photo.png');
        color: transparent;
        overflow: hidden;
        cursor: pointer;
        background-color: white;
        background-position: center;
        background-size: cover;
        width: 140px;
        height: 140px;
        border-radius: 100px;
        padding-left: 130px;
        margin-top: -50px;
      } */

      /* textarea#description,
      textarea#more_info,
      textarea {
        color: #222;
        width: 100%;
        padding: 2rem;
        border-radius: 12px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.45);
      } */

      /* button {
        margin: 1.5rem 0;
        padding: 0;
        border: 0;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        cursor: pointer;
      } */
    }
  `,
]);

export const AuthWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  color: black;
  padding-bottom: 6rem;

  .cropped-img {
    text-align: center;
  }

  form {
    font-family: 'Montserrat', sans-serif;
    display: block;
    justify-content: center;
    flex-direction: column;
    padding: 3rem;

    input:not([type='file']):not([type='checkbox']) {
      font-family: 'Montserrat', sans-serif;
      border-radius: 0.35rem;
      margin: 0.75rem 0;
      border: none;
      &:focus {
        outline: none;
      }
    }

    .ReactCrop {
      max-width: fit-content !important;
    }

    input[type='file'] {
      background-image: url('https://res.cloudinary.com/umuv/image/upload/v1622919587/UMUV/upload_photo.png');
      color: transparent;
      overflow: hidden;
      cursor: pointer;
      background-color: white;
      background-position: center;
      background-size: cover;
      width: 140px;
      height: 140px;
      border-radius: 100px;
      padding-left: 130px;
      margin-top: -50px;
    }

    textarea#description,
    textarea#more_info {
      background: #181818;
      color: #b5b0b0;
      width: 100%;
      padding: 0.55rem;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      color: #b5b0b0 !important;
    }

    .ant-picker-range {
      padding: 0 !important;
    }

    .ant-select {
      margin-top: 0.7rem;
      display: block;
    }

    label {
      color: black;
      font-family: 'Montserrat', sans-serif;
    }

    button {
      margin: 1.5rem 0;
      padding: 0;
      border: 0;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      cursor: pointer;
    }

    .confirm-edit-section {
      display: flex;
      justify-content: space-around;
    }
    .ant-select-selector {
      background: black !important;
    }
  }
`;

export const FormError = styled.div`
  color: red;
  font-size: 0.75rem;
  font-style: italic;
  padding-left: 2px;
`;

export const BrowseWrapper = styled.div`
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MuventWrapper = styled.div`
  font-family: 'Montserrat', sans-serif;
  padding: 1rem;
  color: black;
  background: whitesmoke;
  border: 1px solid #663398;
  img {
    min-width: 120px;
    object-fit: contain;
    max-width: 266px !important;
  }
`;

export const MuventsMain = styled.div`
  grid-template-columns: repeat(auto, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(120px, auto);
`;

export const MuventContainer = styled.div(() => [
  tw`flex flex-col justify-center text-3xl shadow-md shadow-slate-100 border-[1px] rounded-t-[4px] border-slate-100`,
  css`
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
  `,
]);

export const MuventsPageWrapper = styled.div`
  color: #663398;
  display: grid;
  grid-template-columns: 70% auto;
  grid-gap: 1rem;
`;

export const MuventsNews = styled.div`
  font-family: 'Montserrat', sans-serif !important;
  color: black;
  background: whitesmoke;
  border: 1px solid #663398;
  margin: 0.65rem;
  padding: 10px;
  position: relative;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.3s ease;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  border-top: 2px solid aquamarine;
  border-bottom: none;
  border-right: none;
  border-left: none;

  &:hover {
    box-shadow: 0px 0px 20px 0px rgba(50, 46, 51, 0.2);
  }
`;

export const SingleNewsArticleWrapper = styled.div`
  h4 {
    margin-bottom: -8px;
    color: #663398;
    font-style: italic;
    font-size: 0.85rem;
  }
  p {
    font-size: 0.85rem;
  }
  border-bottom: 1px solid lightgrey;
`;

export const Container = styled.div`
  background: purple;
  border: 1px solid #250b46;
  border-radius: 2px;
  padding: 2.75rem 0.35rem;
  display: flex;
  flex-direction: column;
  min-height: 15vh;
  justify-content: center;
  align-items: center;
`;

export const Spacer = styled.div`
  margin-top: ${props => props.marginTop || '1rem'};
  margin-bottom: ${props => props.marginBottom || '1rem'};
`;

export const Spinner = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

export const CountryFilter = styled.div(() => [
  tw`rounded-full flex justify-center items-center gap-2 pl-3 mr-4
  bg-purple-200`,
  css`
    input:not([type='file']):not([type='checkbox']) {
      font-family: 'Montserrat', sans-serif;
      border: none;
      &:focus {
        outline: none;
      }
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.45);
      width: 120%;
      text-transform: capitalize;
    }
  `,
]);

export const LinksWrapper = styled.div(() => [tw`flex flex-col text-center text-blue-600 mb-6`, css``]);

export const MuventImage = styled.img(() => [
  tw`object-cover h-2/4 sm:w-full lg:w-full self-center rounded-t-[4px]`,
  css``,
]);

export const MuventTitle = styled.h1(() => [tw`font-montserrat text-3xl mt-20 text-center font-bold`, css``]);

export const MuventLikeAndShare = styled.div(() => [
  tw`grid grid-cols-3 items-stretch cursor-pointer bg-gray-50 p-2`,
  css``,
]);

export const MuventCreator = styled.p(() => [tw`font-montserrat text-sm text-center italic`, css``]);

export const MuventActions = styled.div(() => [tw`flex justify-center gap-2 my-10`, css``]);

export const MuventActionButton = styled(Button)(() => [
  tw`text-gray-600 px-3 py-2 ml-2 rounded-full bg-white hover:text-white hover:bg-purple-300 shadow-md xs:my-1 border-[1px] border-purple-200`,
]);

export const MuventDescriptionWrapper = styled.div(() => [tw`flex justify-center text-center m-4 md:m-10`, css``]);
export const MuventDescriptionWrapperInner = styled.div(() => [
  tw`lg:w-2/4 md:w-2/4 sm:w-full xs:w-full xs:m-2`,
  css``,
]);
export const MuventDescriptionParagraph = styled.p(() => [tw`whitespace-pre-wrap`, css``]);
export const HomePageBG = styled.div(() => [
  tw`flex flex-col items-center justify-center text-center h-screen md:bg-cover bg-center bg-no-repeat xs:bg-contain`,
  css`
    background-image: url('umuv-cover-1.svg');
    background-repeat: no-repeat;
    background-position: center;
  `,
]);

export const ArticleWrapper = styled.div(() => [
  tw`flex justify-center mt-40`,
  css`
    background-image: url(${props => props.imageName}.svg);
    background-repeat: no-repeat;
    background-position: center;
  `,
]);
