import { useState, useRef, useCallback, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { PostMuventWrapperTwinMacro } from './styles';
import { firestore, storage } from '../lib/firebase.prod';
import { getUUID, isDateInPast, toDashes } from '../helpers/util-fns';
import { useHistory } from 'react-router-dom';
import { useAuthListener } from '../hooks';
import { collection, query, where, getDocs, doc, setDoc, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Select, TextInput, Checkbox, Progress, NumberInput, Button, Textarea, Autocomplete } from '@mantine/core';
import {
  At,
  AddressBook,
  CurrencyEuro,
  Video,
  Folder,
  BluetoothConnected,
  Ticket,
  BrandFirebase,
  TextResize,
  Stack2,
  Writing,
  World,
} from 'tabler-icons-react';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { DatePicker } from '@heroui/react';
import { getLocalTimeZone, now, today } from '@internationalized/date';

import { categoryOptions, countries } from '../helpers/generalData';
import CustomDivider from '../components/CustomDivider';

const PostMuvent = () => {
  const [file, setFile] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [imageName, setImageName] = useState('muventImg');
  const [isCropConfirmed, setIsCropConfirmed] = useState(false);
  const [upImg, setUpImg] = useState();
  const [crop, setCrop] = useState({ unit: 'px', width: 30, aspect: 16 / 9, maxWidth: 1200, maxHeight: 800 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(now(getLocalTimeZone()));
  const [yours, setYours] = useState(false);
  const [free, setFree] = useState(false);
  const [contactEmail, setContactEmail] = useState('');
  const [fee, setFee] = useState(0);
  const [addressOne, setAddressOne] = useState('');
  const [addressTwo, setAddressTwo] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [websiteLink, setWebsiteLink] = useState('');
  const [ticketLink, setTicketLink] = useState('');
  const [applicationFormLink, setApplicationFormLink] = useState('');
  const [moreInfo, setMoreInfo] = useState('');
  const [uploadPhotoProgress, setUploadPhotoProgress] = useState(0);
  const [usersWithNotifsOn, setUsersWithNotifsOn] = useState([]);
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const history = useHistory();
  const { user } = useAuthListener();

  function generatePhotoForUpload(canvas) {
    canvas.toBlob(blob => {
      const image = new Image();
      image.src = blob;
      // set the image with width of 1200px and height of 800px
      setFile(blob);

      // set the thumbnail with width of 300px and height of 200px
      const canvasThumbnail = document.createElement('canvas');
      const ctx = canvasThumbnail.getContext('2d');
      canvasThumbnail.width = 300;
      canvasThumbnail.height = 200;
      ctx.drawImage(image, 0, 0, 300, 200);

      const newFile = new File([blob], image.name, {
        type: 'image/webp',
        lastModified: Date.now(),
      });
      const MAX_SIZE = 1 * 1024 * 1024;
      const quality = MAX_SIZE / newFile.size;
      const compressedDataUrl = canvas.toDataURL('image/webp', quality);

      setThumbnail(compressedDataUrl);

      setIsCropConfirmed(true);
    });
  }

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setImageName(e.target.files[0].name);
      setIsCropConfirmed(false);
    }
  };

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    // set to max of 1200px width and 800px height
    canvas.width = Math.min(1200, crop.width * pixelRatio);
    canvas.height = Math.min(800, crop.height * pixelRatio);
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
  }, [completedCrop]);

  useEffect(() => {
    const notifQuery = query(collection(firestore, 'notifications'), where('email_on_new_muvent', '==', true));

    getDocs(notifQuery).then(snapshot => {
      const usersWithNewMuventEmailNotifs = snapshot.docs.map(contentObj => ({
        userUid: contentObj.id,
        email: contentObj.data().email,
        name: contentObj.data().name,
      }));

      const filteredWithoutOwnUser = usersWithNewMuventEmailNotifs.filter(({ userUid }) => userUid !== user.uid);
      setUsersWithNotifsOn(filteredWithoutOwnUser);
    });
  }, [user.uid]);

  const uploadThumbnail = async docRef => {
    const storageThumbnailRef = ref(storage, 'images/muvents/thumbnails/' + imageName);
    const metadata = { contentType: 'image/webp' };
    const response = await fetch(thumbnail);
    const thumbnailBlob = await response.blob();
    const uploadThumbnailTask = uploadBytesResumable(storageThumbnailRef, thumbnailBlob, metadata);

    uploadThumbnailTask.on(
      'state_changed',
      () => {},
      () => {},
      async () => {
        const downloadURLThumbnail = await getDownloadURL(uploadThumbnailTask.snapshot.ref);
        if (downloadURLThumbnail) {
          await setDoc(
            doc(firestore, 'muvents', docRef.id),
            { thumbnail_300_200: downloadURLThumbnail },
            { merge: true },
          );
        }
      },
    );
  };

  const handleSubmit = async () => {
    if (!file || !title || !description || !date || !country) return;

    const muvent = {
      id: getUUID(),
      created_at: new Date(),
      ownerId: user.uid,
      title,
      slug: toDashes(title),
      category,
      description,
      yours,
      free,
      fee,
      address_1: addressOne,
      address_2: addressTwo,
      video_link: videoLink,
      website_link: websiteLink,
      ticket_link: ticketLink,
      application_form_link: applicationFormLink,
      more_info: moreInfo,
      creator_name: user.displayName,
      contact_email: contactEmail,
      country,
      city,
      date: date?.toDate(),
    };

    const storageRef = ref(storage, 'images/' + imageName);
    const metadata = { contentType: 'image/webp' };
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      'state_changed',
      snapshot => {
        let uploadProgressRaw = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const uploadProgress = Number(uploadProgressRaw.toFixed());
        setUploadPhotoProgress(uploadProgress);
      },
      () => {},
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        muvent.photoURL = downloadURL ?? 'https://res.cloudinary.com/umuv/image/upload/v1631313513/UMUV/umuv_world.png';

        const docRef = await addDoc(collection(firestore, 'muvents'), muvent);
        await uploadThumbnail(docRef);

        const location = {
          pathname: `/muvents/${docRef?.id}`,
          state: {
            title,
            description,
            slug: toDashes(title),
            category,
            photoURL: downloadURL,
            ownerId: user.uid,
            date: date?.toDate(),
            yours,
            fee,
            free,
            address_1: addressOne,
            address_2: addressTwo,
            video_link: videoLink,
            website_link: websiteLink,
            ticket_link: ticketLink,
            application_form_link: applicationFormLink,
            more_info: moreInfo,
            creator_name: user.displayName,
            contact_email: contactEmail,
            country,
            city,
          },
        };

        await setDoc(doc(firestore, 'email_notif_new_muvent', docRef?.id), {
          title: title,
          muvent_category: category,
          muvent_url: `https://umuv.world/muvents/${docRef?.id}`,
          users: usersWithNotifsOn,
          created_by: user.displayName,
        });

        notifications.show({
          id: 'muvent-added',
          withCloseButton: true,
          autoClose: 5000,
          title: `Successfully created your muvent: ${title}`,
          message: `Now that is fun! 🎊`,
          color: 'teal',
          loading: false,
          icon: <IconCheck size="1rem" />,
        });

        history.push(location);
      },
    );
  };

  const getCitiesData = useCallback(() => {
    const citiesArray = countries
      .find(({ name }) => name === country.split(' ').slice(0).join(' '))
      ?.cities?.map?.(({ name }) => name);

    return citiesArray ?? [];
  }, [country]);

  const getCountriesData = () => {
    return countries.map(({ name }) => name);
  };

  return (
    <PostMuventWrapperTwinMacro>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form className="lg:w-3/3 md:w-4/4 sm:w-full xl:w-4/6 bg-white flex justify-center my-10">
            {/*----------------------------------------------*/}
            {/*-------------- PHOTO SECTION -----------------*/}
            {/*----------------------------------------------*/}
            <div className="flex justify-center mb-10 align-center">
              <label htmlFor="file"></label>
              {completedCrop && !completedCrop ? null : (
                <input type="file" accept="image/*" onChange={onSelectFile} className="text-center" />
              )}
            </div>
            {isCropConfirmed ? null : (
              <div className="mt-10">
                <ReactCrop
                  src={upImg}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={c => setCrop(c)}
                  onComplete={c => setCompletedCrop(c)}
                />
              </div>
            )}
            <div className="mt-10">
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0),
                }}
              />
            </div>
            <div className="confirm-edit-section m-2 mb-10">
              {crop && completedCrop && !isCropConfirmed ? (
                <Button
                  leftIcon={<BrandFirebase size={14} />}
                  variant="white"
                  color="indigo"
                  onClick={() => generatePhotoForUpload(previewCanvasRef.current)}
                  className="w-1/4 bg-site-purple text-white rounded-full"
                >
                  confirm
                </Button>
              ) : null}
              {completedCrop ? (
                <Button
                  leftIcon={<BrandFirebase size={14} />}
                  onClick={() => setIsCropConfirmed(false)}
                  className="w-1/4 bg-site-purple text-white rounded-full"
                >
                  edit
                </Button>
              ) : null}
            </div>

            {/*----------------------------------------------*/}
            {/*-------------- DATE TIME SECTION -------------*/}
            {/*----------------------------------------------*/}
            <div className="flex flex-col gap-6 md:flex-row md:justify-between md:gap-10 items-center py-4">
              <DatePicker
                value={date}
                onChange={setDate}
                labelPlacement="outside"
                className="max-w-xs"
                hideTimeZone
                showMonthAndYearPickers
                label="Date and time of your muvent"
                variant="bordered"
                granularity="minute"
                hourCycle="24"
                minValue={today(getLocalTimeZone())}
              />
            </div>
            {/*----------------------------------------------*/}
            {/*-------------- TITLE SECTION -----------------*/}
            {/*----------------------------------------------*/}
            <div className="flex flex-col gap-2 md:flex-row md:justify-between md:gap-10">
              <TextInput
                placeholder="The title of the muvent"
                radius="xs"
                required
                value={title}
                icon={<TextResize size={16} />}
                onChange={event => setTitle(event.currentTarget.value)}
                className="w-full"
              />

              {/*------------------------------------------*/}
              {/*------------ CATEGORY SECTION ------------*/}
              {/*------------------------------------------*/}
              <Select
                placeholder="Pick a category"
                searchable
                nothingFound="No options"
                transition="pop-top-left"
                // transitionDuration={80}
                // transitionTimingFunction="ease"
                icon={<Stack2 size={16} />}
                data={categoryOptions}
                onChange={val => setCategory(val)}
                value={category}
                className="w-full"
              />
            </div>

            {/*----------------------------------------------*/}
            {/*----------- DESCRIPTION SECTION --------------*/}
            {/*----------------------------------------------*/}
            <div className="flex flex-col my-10">
              <Textarea
                icon={<Writing size={16} />}
                placeholder={`Write a short description for your ${category ? category : 'event'}`}
                autosize
                minRows={5}
                onChange={evt => setDescription(evt.currentTarget.value)}
              />
            </div>
            {/*----------------------------------------------*/}
            {/*-------------- EMAIL SECTION -----------------*/}
            {/*----------------------------------------------*/}
            <div className="flex flex-col gap-6 md:flex-row md:justify-between md:gap-10 my-6">
              <TextInput
                placeholder="Contact email"
                icon={<At size={14} />}
                value={contactEmail}
                onChange={event => setContactEmail(event.currentTarget.value)}
                className="w-full"
              />

              {/*------------------------------------------*/}
              {/*-------------- FEE SECTION -------------*/}
              {/*------------------------------------------*/}
              <NumberInput
                hideControls
                placeholder="Fee"
                icon={<CurrencyEuro size={14} />}
                precision={2}
                min={0}
                max={1000}
                value={fee}
                onChange={val => setFee(val)}
                className="w-full"
              />
            </div>
            {/*----------------------------------------------*/}
            {/*------------- ADDRESS SECTION ----------------*/}
            {/*----------------------------------------------*/}
            <div className="flex flex-col gap-4 md:flex-row md:justify-between md:gap-10 my-6">
              <TextInput
                placeholder="Street and number"
                icon={<AddressBook size={14} />}
                value={addressOne}
                onChange={event => setAddressOne(event.currentTarget.value)}
                className="w-full"
              />

              <TextInput
                placeholder="Address Line 2"
                icon={<AddressBook size={14} />}
                value={addressTwo}
                onChange={event => setAddressTwo(event.currentTarget.value)}
                className="w-full"
              />
            </div>
            <div className="flex flex-col gap-4 md:flex-row md:justify-between md:gap-10 my-4">
              <Autocomplete
                icon={<World size={16} />}
                value={country}
                onChange={val => {
                  setCity('');
                  setCountry(val);
                }}
                placeholder="Country: Type the country to see more options"
                data={getCountriesData()}
                className="w-full"
              />

              <Autocomplete
                value={city}
                onChange={setCity}
                placeholder={country?.length ? `City: Type the city to see more options` : `Choose the country first`}
                data={getCitiesData()}
                className="w-full"
              />
            </div>
            {/*----------------------------------------------*/}
            {/*-------------- LINKS SECTION -----------------*/}
            {/*----------------------------------------------*/}
            <CustomDivider title="Links" />
            <div className="flex flex-col gap-1 md:flex-row md:justify-between md:gap-10">
              <TextInput
                placeholder="Video link"
                icon={<Video size={14} />}
                onChange={event => setVideoLink(event.currentTarget.value)}
                className="w-full"
              />
              <TextInput
                placeholder="Website link"
                icon={<BluetoothConnected size={14} />}
                onChange={event => setWebsiteLink(event.currentTarget.value)}
                className="w-full"
              />{' '}
            </div>
            <div className="flex flex-col gap-1 md:flex-row md:justify-between md:gap-10 my-4">
              <TextInput
                placeholder="Ticket link"
                icon={<Ticket size={14} />}
                onChange={event => setTicketLink(event.currentTarget.value)}
                className="w-full"
              />
              <TextInput
                id="application_form_link"
                placeholder="Application form link"
                icon={<Folder size={14} />}
                onChange={event => setApplicationFormLink(event.currentTarget.value)}
                className="w-full"
              />
            </div>
            <div className="mt-20"></div>
            {/*----------------------------------------------*/}
            {/*-------------- MORE INFO SECTION -------------*/}
            {/*----------------------------------------------*/}
            <CustomDivider title="More Info" />
            <div className="flex flex-col gap-6 md:flex-row md:justify-between md:gap-10">
              <Checkbox
                label="Is this a FREE event?"
                radius="xs"
                onChange={evt => setFree(evt.currentTarget.checked)}
                checked={free}
                className="w-full"
              />

              <Checkbox
                label="Is this your event?"
                radius="xs"
                onChange={evt => setYours(evt.currentTarget.checked)}
                checked={yours}
                className="w-full"
              />
            </div>
            <div className="flex flex-col gap-6 md:flex-row md:justify-between md:gap-10 my-8">
              <Textarea
                icon={<Writing size={16} />}
                placeholder={`If you have more info for your ${category ? category : 'event'} write it here`}
                autosize
                minRows={5}
                onChange={evt => setMoreInfo(evt.target.value)}
                className="w-full"
              />
            </div>
            <div className="mt-20"></div>
            {uploadPhotoProgress ? (
              <Progress value={uploadPhotoProgress} label={`${uploadPhotoProgress}%`} size="xl" radius="xl" />
            ) : null}

            {!file || !title || !description || !date || !country ? (
              <div className=" border-red-200 border-1 border-spacing-1 p-2 rounded-xl">
                <small className="text-red-600">Missing information: Please add the missing info to your event.</small>
                <ul className="py-2">
                  {!file && (
                    <li>
                      <small>
                        <em className="text-red-600 pr-2">Photo: </em> Please upload a photo
                      </small>{' '}
                    </li>
                  )}
                  {isDateInPast(date) && (
                    <li>
                      <small>
                        <em className="text-red-600 pr-2">Date: </em> Choose a valid date for your event
                      </small>{' '}
                    </li>
                  )}

                  {!title && (
                    <li>
                      <small>
                        <em className="text-red-600 pr-2">Title: </em> Add a title to your event
                      </small>{' '}
                    </li>
                  )}
                  {!description && (
                    <li>
                      <small>
                        <em className="text-red-600 pr-2">Description: </em> Add a short description to your event
                      </small>{' '}
                    </li>
                  )}

                  {!date && (
                    <li>
                      <small>
                        <em className="text-red-600 pr-2">Date: </em> Choose a date
                      </small>{' '}
                    </li>
                  )}
                  {!country && (
                    <li>
                      <small>
                        <em className="text-red-600 pr-2">Country: </em> Choose a country for your event
                      </small>{' '}
                    </li>
                  )}
                </ul>
              </div>
            ) : null}

            {/*----------------------------------------------*/}
            {/*-------------- ACTION BUTTONS ----------------*/}
            {/*----------------------------------------------*/}
            <Button
              leftIcon={<BrandFirebase size={14} />}
              type="submit"
              disabled={
                isSubmitting ||
                !file ||
                !title ||
                uploadPhotoProgress ||
                !description ||
                !date ||
                !country ||
                isDateInPast(date)
              }
              className="w-full rounded text-xl h-16 bg-purple-300 hover:bg-gray-100 hover:text-purple-600 text-slate-100"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </PostMuventWrapperTwinMacro>
  );
};

export default PostMuvent;
