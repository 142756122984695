import dayjs from 'dayjs';

export function getUUID() {
  // eslint gets funny about bitwise
  /* eslint-disable */
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const piece = (Math.random() * 16) | 0;
    const elem = c === 'x' ? piece : (piece & 0x3) | 0x8;
    return elem.toString(16);
  });
  /* eslint-enable */
}

export function toDashes(str) {
  if (!str || typeof str !== 'string') {
    return ''; // Return an empty string if the input is invalid
  }

  return str
    .trim() // Remove leading and trailing spaces
    .split(/\s+/) // Split by one or more spaces
    .join('-') // Join with dashes
    .toLowerCase(); // Convert to lowercase
}

export function truncateString(str, length) {
  return str && str.length > length ? `${str.slice(0, length)}...` : str;
}

export function removeDashes(str) {
  return str?.length ? str.replaceAll('-', ' ') : str;
}

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export function formatNumber(number) {
  if (number >= 1000) {
    const suffixes = ['', 'k', 'M', 'B', 'T'];
    const suffixNum = Math.floor(('' + number).length / 3);
    let shortNumber = parseFloat((suffixNum !== 0 ? number / Math.pow(1000, suffixNum) : number).toPrecision(2));
    if (shortNumber % 1 !== 0) {
      shortNumber = shortNumber.toFixed(1);
    }
    return shortNumber + suffixes[suffixNum];
  }
  return number;
}

export function isDateInPast(inputDate) {
  // Get today's date at the start of the day (00:00:00)
  const today = dayjs().startOf('day');
  // Check if the input date is before today
  return dayjs(inputDate?.toDate()).startOf('day').isBefore(today);
}
